<template>
  <div class="app-content-box bg-gray">
    <div v-if="isLoading">
      <Loader />
    </div>
    <div class="search-wrapper" v-else>
      <div class="container">
        <div class="top-heading-medium mt-4">
          {{popularGenres.label}}
        </div>
        <div class="books-category-bars">
          <ul
            class="books-category-bars-list"
            v-for="(genres, key) in popularGenres.items"
            :key="key"
          >
            <li>
              <router-link :to="{name: 'TagsDetails', params: {id: genres.id}}">
                <img :src="plainUrl + '/img/genres/' + genres.img_hor" :alt="genres.label"/>
              </router-link>
            </li>
          </ul>

        <router-link to="/kategorie">
            <a class="btn-black-full w-100 mt-4">Zobacz więcej</a>
        </router-link>
        </div>
      </div>
      <div>
        <div class="container">
          <hr class="mt-4 section-spacer" />
          <div class="book-heading-small mt-5 text-center">{{popularInWeek.label}}</div>
          <div class="book-heading-big mt-3 text-center">{{popularInWeek.title}}</div>
        </div>
        <div>
          <div class="growing-cover-slider">
          <UserProfileSlider :props="popularInWeek.items" :componentId="inWeekId"/>
          </div>
            <router-link :to="{name: 'Authors', params: {id: weekBookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{weekBookData.autor}}</div></router-link>
            <router-link :to="{name: 'BooksDetails', params: {id: weekBookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{weekBookData.tytul}}</div></router-link>
          <div class="stars-wrapper mt-1" v-if="weekBookData.rating > 0">
            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :show-rating="false" :read-only="true"
            :rounded-corners='rounded' :rating="parseFloat(weekBookData.rating, 10)" >
            </star-rating>
          </div>
          <div class="text-center mt-2 rating-size"  v-if="weekBookData.rating > 0">Ocena: {{weekBookData.rating}}</div>
        </div>
      </div>
      <div>
        <div class="container">
          <hr class="mt-4 section-spacer" />
            <div class="book-heading-small mt-5 text-center">{{popularInMonth.label}}</div>
            <div class="book-heading-big mt-3 text-center">{{popularInMonth.title}}</div>
        </div>
        <div>
          <div class="growing-cover-slider">
          <UserProfileSlider :props="popularInMonth.items" :componentId="inMonthId"/>
          </div>
          <div class="book-heading-container">
            <router-link :to="{name: 'Authors', params: {id: monthBookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{monthBookData.autor}}</div></router-link>
            <router-link :to="{name: 'BooksDetails', params: {id: monthBookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{monthBookData.tytul}}</div></router-link>
            <div class="stars-wrapper mt-1" v-if="monthBookData.rating > 0">
              <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
              inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
              :border-width='borderWidth' :show-rating="false" :read-only="true"
              :rounded-corners='rounded' :rating="parseFloat(monthBookData.rating, 10)" >
              </star-rating>
            </div>
            <div class="text-center mt-2 rating-size"  v-if="monthBookData.rating > 0">Ocena: {{monthBookData.rating}}</div>
          </div>
        </div>
      </div>
      <!-- <BookDetailsWrapper :props="popularInWeek"/>
      <BookDetailsWrapper :props="popularInMonth"/> -->
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import Loader from './Loader'
import UserProfileSlider from './UserProfileSlider'
import StarRating from 'vue-star-rating'
export default {
  name: 'SearchTopCategories',
  components: {
    Loader,
    UserProfileSlider,
    StarRating
  },
  async created () {
    await this.$https('/search', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.popularGenres = res.data.response[0]
        this.popularInWeek = res.data.response[1]
        this.popularInMonth = res.data.response[2]
        EventBus.$on('books-in-week', (payload) => {
          this.weekData(payload)
        })
        EventBus.$on('books-in-month', (payload) => {
          this.monthData(payload)
        })
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
  },
  data () {
    return {
      inWeekId: 'week',
      inMonthId: 'month',
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18,
      isLoading: true,
      popularGenres: [],
      popularInWeek: [],
      popularInMonth: [],
      topMothData: [],
      topWeekData: [],
      newMonthData: [],
      weekBookData: { autor: '', tytul: '', rating: '', id: 0, autor_id: 0 },
      monthBookData: { autor: '', tytul: '', rating: '', id: 0, autor_id: 0 }
    }
  },
  methods: {
    weekData (payload) {
      this.weekBookData = payload
    },
    monthData (payload) {
      this.monthBookData = payload
    }
  },
  beforeDestroy () {
    EventBus.$off('books-in-week', (payload) => {
      this.weekData(payload)
    })
    EventBus.$off('books-in-month', (payload) => {
      this.monthData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .search-wrapper {
  margin-top: 55px;
  // margin-bottom: 25px;
  }
  .book-heading-container {
    height: 150px;
  }
  .stars-wrapper {
  display: flex;
  justify-content: center;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
